import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export default React.memo(function Carousel({
                                              children,
                                              slidesPerView = 1,
                                              animationTime = 0.3,
                                              skipCount = 1,
                                              loop = 0,
                                              partialShow = false,
                                              partGutter = 0, //%
                                              className = '',
                                              containerClassName = '',
                                              itemClassName = 'items-center',
                                              callback,
                                              applyControls,
                                              ref
                                            }) {
  const lastClientX = useRef(0);
  const lastElementClicked = useRef(null);
  const timestampDragStart = useRef(null);
  const timestampDragEnd = useRef(null);
  const [state, setState] = useState({
    posInitial: 0,
    threshold: 10, // % relative to slide
    slidesLength: children.length,
    index: 0,
    allowShift: true,
    isDrag: false,
  });

  useEffect(() => {
    setState(prev => ({ ...prev, slidesLength: children.length }));
  }, [children]);

  const sliderRef = useRef(null);
  const items = useMemo(() => {
    if (!children.length) return [];
    const frontClonesCount = partialShow ? slidesPerView + 1 : slidesPerView;

    return [
      React.cloneElement(children[children.length - 1]),
      ...children,
      ...Array.from({ length: frontClonesCount }, (_, i) => React.cloneElement(children[i]))
    ];
  }, [children, slidesPerView, partialShow]);

  const sliderWidth = useMemo(() => {
    return items.length * 100 / slidesPerView;
  }, [items.length, slidesPerView]);

  const slideWidth = useMemo(() => {
    const width = 100 / items.length;
    const gutter = partialShow ? partGutter / 100 * width : 0;

    return width - gutter;
  }, [items.length, partGutter, partialShow]);

  const thresholdValueRelativeToItem = useMemo(() => {
    return slideWidth / 100 * state.threshold;
  }, [state.threshold, slideWidth]);

  const getCurrentTranslate = (transform) => {
    const translateX = transform.match(/-?\d+(\.\d+)?/)[0];
    return parseFloat(translateX) || 0;
  };

  const getNextIndex = (index, slidesCount) => {
    let nextIndex = index;
    if (index === slidesCount) {
      nextIndex = 0;
    } else if (index === -1) {
      nextIndex = slidesCount - 1
    }
    return nextIndex;
  };

  const tryRestoreClick = useCallback(() => {
    if (timestampDragEnd.current - timestampDragStart.current > 120 ||
      !lastElementClicked.current ||
      !lastElementClicked.current.isConnected) return;

    if (lastElementClicked.current.click) {
      lastElementClicked.current.click();
    } else {
      lastElementClicked.current.dispatchEvent(new Event('click', { bubbles: true }));
    }
  }, [])

  const dragStart = useCallback((e) => {
    lastElementClicked.current = e.target;
    timestampDragStart.current = Date.now();
    if (!state.allowShift) return;
    let posInitial = getCurrentTranslate(sliderRef.current.style.transform);

    let startX;
    if (e.type === 'touchstart') {
      startX = e.touches[0].clientX;
    } else {
      startX = e.clientX;
    }

    setState(prev => ({ ...prev, isDrag: true, posInitial }));
    lastClientX.current = startX;
  }, [state.allowShift]);

  const dragAction = useCallback((e) => {

    let startX, diff;
    if (e.type === 'touchmove') {
      diff = lastClientX.current - e.touches[0].clientX;
      startX = e.touches[0].clientX;
    } else {
      diff = lastClientX.current - e.clientX;
      startX = e.clientX;
    }

    const translateDiff = diff * 100 / sliderRef.current.offsetWidth;
    requestAnimationFrame(() => {
      sliderRef.current.style.transform = `translateX(${getCurrentTranslate(sliderRef.current.style.transform) - translateDiff}%) translateZ(0)`;
    })
    lastClientX.current = startX;
  }, []);

  const dragEnd = useCallback((e) => {
    timestampDragEnd.current = Date.now();

    let posFinal = getCurrentTranslate(sliderRef.current.style.transform);
    if (posFinal - state.posInitial < -thresholdValueRelativeToItem) {
      shiftSlide(1, 'drag');
    } else if (posFinal - state.posInitial > thresholdValueRelativeToItem) {
      shiftSlide(-1, 'drag');
    } else {
      sliderRef.current.classList.add('transition-all');
      sliderRef.current.style.transform = `translateX(${state.posInitial}%) translateZ(0)`;
    }
    setState(prev => ({ ...prev, isDrag: false }));

    tryRestoreClick();
  }, [state.posInitial, thresholdValueRelativeToItem, tryRestoreClick]);

  const shiftSlide = useCallback((dir, action) => {
    if (!state.allowShift) return;

    sliderRef.current.classList.add('transition-all');
    sliderRef.current.style.transitionDuration = `${animationTime}s`;

    let posInitial = state.posInitial;

    if (!action) {
      posInitial = getCurrentTranslate(sliderRef.current.style.transform);
    }

    if (dir > 0) {
      sliderRef.current.style.transform = `translateX(${posInitial - (slideWidth * Math.abs(dir))}%) translateZ(0)`;
      setState(prev => ({
        ...prev,
        index: getNextIndex(prev.index + Math.abs(dir), state.slidesLength),
        posInitial
      }));
    } else if (dir < 0) {
      sliderRef.current.style.transform = `translateX(${posInitial + (slideWidth * Math.abs(dir))}%) translateZ(0)`;
      setState(prev => ({
        ...prev,
        index: getNextIndex(prev.index - Math.abs(dir), state.slidesLength),
        posInitial
      }));
    }
    setState(prev => ({ ...prev, allowShift: false }));
  }, [state.allowShift, state.slidesLength, state.posInitial, slideWidth]);

  const shiftPosition = useCallback((e) => {
    if (e.target !== sliderRef.current) return;

    sliderRef.current.classList.remove('transition-all');
    sliderRef.current.style.transitionDuration = ``;

    if (state.index === 0) {
      sliderRef.current.style.transform = `translateX(${-slideWidth}%) translateZ(0)`;
    }

    if (state.index === state.slidesLength - 1) {
      sliderRef.current.style.transform = `translateX(${-(state.slidesLength * slideWidth)}%) translateZ(0)`;
    }

    setState(prev => ({ ...prev, allowShift: true }));
  }, [state.index, state.slidesLength, items, slideWidth]);

  useEffect(() => {
    callback && callback(state.index);
  }, [callback, state.index]);

  useEffect(() => {
    if (!loop) return;
    let loopTimeout;

    if (state.allowShift && !state.isDrag) {
      loopTimeout = setTimeout(() => shiftSlide(1), loop);
    }

    return () => {
      clearTimeout(loopTimeout);
    }
  }, [loop, state.isDrag, state.allowShift, shiftSlide]);

  useEffect(() => {
    if (state.isDrag) {
      window.addEventListener('touchend', dragEnd);
      window.addEventListener('touchmove', dragAction);
      window.addEventListener('mouseup', dragEnd);
      window.addEventListener('mousemove', dragAction);
    }

    return () => {
      window.removeEventListener('touchend', dragEnd);
      window.removeEventListener('touchmove', dragAction);
      window.removeEventListener('mouseup', dragEnd);
      window.removeEventListener('mousemove', dragAction);
    }
  }, [state.isDrag, dragAction, dragEnd]);

  const showNext = useCallback(() => {
    shiftSlide(1);
  }, [shiftSlide]);

  const showPrevious = useCallback(() => {
    shiftSlide(-1);
  }, [shiftSlide]);

  const goTo = useCallback((index) => {
    const shift = index - state.index;
    if (shift === 0) return;
    shiftSlide(shift);
  }, [shiftSlide, state.index]);

  const getCurrentIndex = useCallback(() => {
    return state.index;
  }, [state.index]);

  useImperativeHandle(ref, () => ({
    showNext,
    showPrevious,
    goTo,
    getCurrentIndex,
  }));

  const controls = useMemo(() => {
    return applyControls && typeof applyControls === 'function' ? applyControls({
      showNext,
      showPrevious,
      getCurrentIndex,
      goTo,
      totalCount: state.slidesLength
    }) : null;
  }, [showNext, showPrevious, getCurrentIndex, goTo, state.slidesLength]);

  return (
    <div className={`w-full h-full select-none relative ${containerClassName}`}>
      <div className={`relative z-[1] w-full h-full overflow-hidden ${className}`}>
        <div ref={sliderRef} onMouseDown={dragStart} onTouchStart={dragStart} onTransitionEnd={shiftPosition}
             className={`relative h-full ${partialShow ? 'flex' : 'grid'} top-0 left-0 will-change-transform`}
             style={{
               gridTemplateColumns: `repeat(${items.length}, minmax(0, 1fr))`,
               width: `${sliderWidth}%`,
               transform: `translateX(-${slideWidth}%) translateZ(0)`,
               transformStyle: 'preserve-3d',
               backfaceVisibility: 'hidden',
             }}>

          {items.map((slide, i) =>
            <div key={i}
                 className={`h-full flex justify-center overflow-hidden ${state.isDrag ? 'pointer-events-none' : ''} ${itemClassName}`}
                 style={{ width: partialShow ? `${slideWidth}%` : '100%' }}>
              {slide}
            </div>
          )}

        </div>
      </div>

      <div>
        {controls}
      </div>
    </div>
  );
});
